import promotionsCategory from "./components/promotions-category/index.vue";
import promotionsProduct from "@/modules/promotions/components/promotions-product/index.vue";
import { directive as onClickaway } from "vue-clickaway";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { globalLoader } from "../../helpers/variables/index";

export default {
    name: 'promotions',
    directives: {onClickaway},
    data() {
        return {
            openCategory: false,
            selectedBrand: null,
            paginator: {hasNextPage: false, page: 1, perPage: 6, brand: 1, paginationLoading: false},
        };
    },
    components: {promotionsCategory, promotionsProduct},
    async created() {
        this.changePromotionLoader(true);
        globalLoader(true)
        await this.loadProducts();
        await this.loadBrands();
        globalLoader(false)
        this.changePromotionLoader(false);
    },
    computed: {
        ...mapGetters({
            promotionsProducts: "promotions/promotionsProducts",
            promotionsLoading: "promotions/promotionsLoading",
            promotionsBrands: "brands/promotionsBrands",
            loadingBrands: "brands/loadingBrands"
        })
    },
    methods: {
        ...mapActions({
            fetchPromotionsProducts: 'promotions/FETCH_PROMOTIONS_PRODUCTS',
            fetchPromotionsBrands: "brands/FETCH_PROMOTIONS_BRANDS",
        }),
        ...mapMutations({
            changePromotionLoader: 'promotions/SET_PROMOTIONS_LOADING'
        }),

        async selectBrand(brand) {
            this.selectedBrand = brand;
            this.paginator.perPage = 6;
            this.changePromotionLoader(true);
            await this.loadProducts();
            this.openCategory = false;
            this.changePromotionLoader(false);
        },


        async loadProducts(page, perPage) {
            try {
                const response = await this.fetchPromotionsProducts({page, perPage, brand: this.selectedBrand?.id})
                this.paginator.hasNextPage = !!response.links.next
                console.log('Продукти акцій успішно завантажено');
            } catch (error) {
                console.error('Помилка при завантаженні продуктів акцій:', error);
            } finally {
                this.paginator.paginationLoading = false
            }
        },

        async loadNextPage() {
            this.paginator.perPage += 6;
            this.paginator.paginationLoading = true
            await this.loadProducts(this.paginator.page, this.paginator.perPage);
        },

        async loadBrands() {
            await this.fetchPromotionsBrands()
                .then(() => {
                    console.log('Бренди акцій успішно завантажено');
                })
                .catch(error => {
                    console.error('Помилка при завантаженні брендів акцій:', error);
                });
        },


        openCategories() {
            this.openCategory = !this.openCategory;
            document.getElementById("html").classList.toggle("hide", this.openCategory);
        }


    }
};










